import React from "react";

import { FaWhatsapp, FaEnvelope, FaPhone } from "react-icons/fa";

import { Container, Content, Body } from "./styles";

export default function Address() {
  return (
    <Container>
      <iframe
        title="Mapa"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.0886972136173!2d-43.20959258560108!3d-22.983765646399338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9bd505db63e145%3A0xc0ddff30d66c639!2sR.%20Maria%20Quit%C3%A9ria%2C%2083%20-%20Ipanema%2C%20Rio%20de%20Janeiro%20-%20RJ%2C%2022410-040!5e0!3m2!1spt-BR!2sbr!4v1655223442010!5m2!1spt-BR!2sbr"
        id="fitvid535131"
        width="100%"
        height="271"
      />

      <Content>
        <Body>
          <p>
            Rua Visconde de Pirajá, 414 - sala 718
            <br />
            Ipanema, Rio de Janeiro/RJ
            <br />
            CEP: 22410-002
          </p>

          <p>
            <FaPhone size={20} />
            <span>: +55 (21) 3148-6165</span>
          </p>
          <p>
            <FaWhatsapp size={20} />
            <span>: +55 (21) 99696-1157</span>
          </p>
          <p>
            <FaEnvelope size={20} />
            <span>: contato@mtistudio.com</span>
          </p>
        </Body>
      </Content>
    </Container>
  );
}
