import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 33% 33% 33%;
  grid-gap: 50px;
  top: 0;
  left: 0;

  img {
    opacity: 0;
  }

  img:nth-child(1) {
    width: 180px;
    animation: nuvem 18s 18s linear infinite;
  }
  img:nth-child(2) {
    width: 150px;
    animation: nuvem 15s 15s linear infinite;
  }
  img:nth-child(3) {
    width: 250px;
    animation: nuvem 25s 25s linear infinite;
  }
  img:nth-child(4) {
    width: 200px;
    animation: nuvem 20s 20s linear infinite;
  }
  img:nth-child(5) {
    animation: nuvem 18s 18s linear infinite;
  }
  img:nth-child(6) {
    animation: nuvem 15s 15s linear infinite;
  }
  img:nth-child(7) {
    animation: nuvem 25s 25s linear infinite;
  }
`;
