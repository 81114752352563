import React from 'react';

import nuve1 from 'images/nuve1.png';
import nuve2 from 'images/nuve1.png';
import nuve3 from 'images/nuve1.png';
import nuve4 from 'images/nuve1.png';
import nuve5 from 'images/nuve1.png';
import nuve6 from 'images/nuve1.png';
import nuve7 from 'images/nuve1.png';

import { Container } from './styles';

function Nuvens() {
  return (
    <Container>
      <img src={nuve1} alt="Nuvem 1" />
      <img src={nuve2} alt="Nuvem 2" />
      <img src={nuve3} alt="Nuvem 3" />
      <img src={nuve4} alt="Nuvem 4" />
      <img src={nuve5} alt="Nuvem 5" />
      <img src={nuve6} alt="Nuvem 6" />
      <img src={nuve7} alt="Nuvem 7" />
    </Container>
  );
}

export default Nuvens;
