import React, { useState, useEffect } from 'react';
import { MdMenu } from 'react-icons/md';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { MainMenu, NavList, NavItem } from './styles';

export default function Menu() {
  const [visible, setVisible] = useState(false);
  const menuitems = [
    {
      name: 'Quem Somos',
      url: '/#quem-somos',
    },
    {
      name: 'Como Funciona',
      url: '/#como-funciona',
    },
    {
      name: 'Diferenciais',
      url: '/#diferenciais',
    },

    {
      name: 'Gamificação',
      url: '/#gamificacao',
    },
    {
      name: 'Contato',
      url: '/#contato',
    },
    {
      name: 'Meu Painel',
      url: 'https://painel.flupe.com.br/login',
    },
  ];
  const [menus, setMenus] = useState(menuitems);

  useEffect(() => {
    setMenus(menuitems);
  }, []);

  function handleToggleVisible() {
    setVisible(!visible);
  }
  return (
    <MainMenu>
      <MdMenu onClick={handleToggleVisible} color="#008e98" size={20} className="menu-mobile" />
      <NavList visible={visible} onClick={() => setVisible(false)}>
        {menus.map((menu, key) => (
          <NavItem key={String(key)} active={menu.active}>
            <AnchorLink to={menu.url} title={menu.name} stripHash />
          </NavItem>
        ))}
      </NavList>
    </MainMenu>
  );
}
