import styled from 'styled-components';

export const Section = styled.section`
  margin: 120px 0;
`;

export const HeaderSection = styled.section`
  background: var(--color-background-header);
  display: flex;
  align-items: center;
  height: 120px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  left: 0;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;
  .logo {
    width: 180px;
  }

  &.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.columns {
    display: flex;
    justify-content: space-between;

    align-items: center;
    .child {
      margin: 0 auto;
      z-index: 2;
    }

    .baleia,
    .ilhas {
      animation: float 6s ease-in-out infinite;
      animation-delay: -1s;
    }

    .ilhas {
      animation-delay: -3s;
    }

    .barcos {
      display: flex;
      flex-direction: column;
      width: 50%;

      img:first-child {
        width: 50%;
        animation: float 6s ease-in-out infinite;
        animation-delay: -2s;
      }
      img:last-child {
        width: 50%;
        align-self: flex-end;
        animation: float 6s ease-in-out infinite;
        animation-delay: -3s;
      }
    }

    @media (max-width: 980px) {
      grid-template-columns: auto;
      grid-template-rows: auto;

      .child:first-child {
        margin-bottom: 30px;
      }
    }
  }

  &.bg-white {
    background: #ffffff;
    padding: 30px;
    border-radius: 30px;
  }

  @media (max-width: 980px) {
    padding: 0 30px;
    img {
      max-width: 100%;
    }
    &.columns {
      flex-direction: column;
    }
    .barcos {
      img {
        width: 100% !important;
      }
      img:last-child {
        display: none;
      }
    }
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 160px;
  z-index: 2;
  position: relative;

  img {
    width: 100%;
    z-index: 1;
    animation: float 6s ease-in-out infinite;
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -100px;
    z-index: 3;

    h1 {
      font-size: 3rem;
      width: 300px;
      text-align: center;
      color: var(--color-menu);
      line-height: 3rem;
      margin-bottom: 10px;
    }

    a {
      background-color: var(--color-whatsapp-button-background);
      color: var(--color-whatsapp-button-text);
      padding: 5px 10px;
      font-size: 2rem;
      display: flex;
      align-items: center;
      border-radius: 30px 30px 30px 30px;
      svg {
        margin-right: 10px;
      }
    }
  }
  @media (max-width: 980px) {
    div {
      margin-bottom: 0px;
    }
  }
`;

export const Polvo = styled.img`
  position: sticky;
  top: 160px;
  width: 150px;
  z-index: 20;
  display: block;
  margin: 0 auto;
  animation: parachute 2s ease-in-out infinite;
  transform-origin: 50% 0;
  @media (max-width: 980px) {
    display: none;
  }
`;

export const Article = styled.article`
  max-width: 300px;
  min-height: 300px;
  overflow: hidden;
  width: 100%;
  background: #fff;
  border-radius: 30px 30px 30px 30px;

  .scroll {
    padding: 10px;
  }

  p {
    font-size: 1.5rem;
  }

  h4 {
    color: #fff;
    font-size: 2rem;
    text-align: center;

    padding: 10px;
    background-color: ${(props) => (props.titleColor ? props.titleColor : '#000')};
  }

  h5 {
    color: var(--color-menu);
    font-size: 1.7rem;
  }
`;
