import React from 'react';

import { FaWhatsapp } from 'react-icons/fa';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { ToastContainer } from 'react-toastify';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'gatsby';

import Menu from 'components/Menu';
import Galeria from 'components/Galeria';
import Galeria2 from 'components/Galeria2';
import Address from 'components/Address';
import Contact from 'components/Contact';
import Nuvens from 'components/Nuvens';

import logo from 'images/logo.svg';
import casas from 'images/casas.png';
import polvo from 'images/polvo.png';
import celular from 'images/celular.png';
import barco1 from 'images/barco1.png';
import barco2 from 'images/barco2.png';
import baleia from 'images/baleia.png';
import ilhas from 'images/ilhas.png';

import {
  Section,
  HeaderSection,
  Container,
  Main,
  Polvo,
  Article,
} from 'components/styles';

import 'react-toastify/dist/ReactToastify.css';

export default function Home() {
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Ok"
        cookieName="gatsby-gdpr-google-analytics"
        style={{
          width: 'calc(100% - 40px)',
          left: '50%',
          transform: 'translateX(-50%)',
          marginBottom: '20px',
          borderRadius: '10px',
          fontWeight: '500',
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
        }}
      >
        Nós utilizamos cookies e tecnologias semelhantes para melhorar a sua
        experiência com os nossos serviços, a fim de personalizar as
        publicidades e recomendar conteúdo de seu interesse, seguindo a nossa{' '}
        <Link
          to="#"
          style={{
            color: '#fff',
            fontWeight: 700,
            textDecoration: 'underline',
          }}
        >
          Política de Privacidade
        </Link>
        . Ao continuar navegando neste site, você concorda com tal
        monitoramento.
      </CookieConsent>
      <ToastContainer />

      <Nuvens />
      <HeaderSection>
        <Container className="top">
          <header>
            <AnchorLink to="/#start" title="Flupe" stripHash>
              <img className="logo" src={logo} alt="Flupe" />
            </AnchorLink>
          </header>
          <Menu />
        </Container>
      </HeaderSection>

      <Section id="start">
        <Main>
          <div>
            <h1>Aperte o Play e deixe o resto com a gente!</h1>
            <a
              href="https://api.whatsapp.com/send?phone=5521996961157&text=Olá%20eu%20gostaria%20de%20contratar%20a%20plataforma%20Flupe..."
              target="_blank"
              rel="noopener norefferer"
            >
              <FaWhatsapp /> Entre em contato
            </a>
          </div>
          <img src={casas} alt="Ilhas" />
        </Main>
      </Section>
      <Polvo
        data-sal="slide-down"
        data-sal-delay="300"
        data-sal-easing="ease"
        src={polvo}
        alt="MTI Studio"
      />
      <Section>
        <Container className="columns">
          <Article
            titleColor="#fa822c"
            className="child box"
            id="quem-somos"
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <h4>Quem somos</h4>
            <div className="scroll">
              <p>
                O Flupe é uma plataforma de atividades e conteúdo gamificado,
                criada pela MTI Studio, com o objetivo de tornar a jornada do
                aprendizado mais dinâmica e divertida.
              </p>
            </div>
          </Article>
          <img src={celular} alt="Mobile" className="child" />
        </Container>
      </Section>
      <Section>
        <Container className="columns">
          <div className="barcos">
            <img src={barco1} alt="Barco" width="300" />
            <img src={barco2} alt="Barco" width="300" />
          </div>
          <Article
            titleColor="#6e9c20"
            className="child box"
            id="como-funciona"
            data-sal="slide-left"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <h4>Como funciona</h4>
            <div className="scroll">
              <p>
                O Flupe possui dezenas de atividades gamificadas e minigames
                para trabalhar o conteúdo da sua empresa. Combinamos todas as
                mecânicas de jogos aos métodos tradicionais, como vídeos,
                imagens e textos, fazendo um mix super atrativo para tornar a
                jornada do aprendizado muito mais engajadora!
              </p>
            </div>
          </Article>
        </Container>
      </Section>
      <Section>
        <Container className="columns">
          <Article
            titleColor="#187088"
            className="child box"
            id="diferenciais"
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <h4>Diferenciais</h4>
            <div className="scroll">
              <h5>Progressão e Performance</h5>
              <p>
                Acompanhe o desempenho dos usuários durante todo o
                desenvolvimento de aprendizagem.
              </p>
              <h5>Gestão e Inteligência de dados</h5>
              <p>
                Tome decisões mais precisas a partir da interação do público com
                o seu conteúdo nos jogos.
              </p>
              <h5>Personalização de personagens</h5>
              <p>
                Use seus próprios personagens para criar uma interação Maior com
                seus usuários.
              </p>
              <h5>Novo challange Mode ( Em breve )</h5>
              <p>
                Os alunos podem desafiar outros jogadores para competir numa
                disputa onde ele e seu amigo respondem a uma atividade
                aleatória. No final quem tiver atingido a maior pontuação em
                menos tempo é declarado vencedor!
              </p>
            </div>
          </Article>
          <img src={baleia} alt="Baleia" className="child baleia" width="700" />
        </Container>
      </Section>
      <Section>
        <Container className="columns">
          <img src={ilhas} alt="Ilhas" className="child ilhas" width="250" />
          <Article
            titleColor="#e03835"
            className="child box"
            id="gamificacao"
            data-sal="slide-left"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <h4>Gamificação</h4>
            <div className="scroll">
              <p>
                Com o Flupe o seu conteúdo fica muito mais divertido! Planeje
                suas atividades utilizando dezenas de templates animados e
                interativos.
              </p>
            </div>
          </Article>
        </Container>
      </Section>
      <Section>
        <Container>
          <Galeria />
          <Galeria2 />
        </Container>
      </Section>
      <Section id="contato">
        <Container className="columns bg-white">
          <Address />
          <Contact />
        </Container>
      </Section>
    </>
  );
}
