import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  h3 {
    color: var(--color-menu);
    margin-bottom: 20px;
    width: 300px;
    font-weight: normal;
    font-size: 1.5rem;
  }
  form {
    display: flex;
    flex-direction: column;
    label {
      width: 100%;
      color: var(--color-menu);
      font-weight: bold;
      font-size: 1.5rem;

      input,
      textarea {
        margin-top: 5px;
        margin-bottom: 10px;
        width: 100%;
        border: 0;
        padding: 10px;
        background: var(--color-background-menu);
      }
    }
    button {
      background: #f0a726;
      color: #fff;
      border: none;
      width: 100%;
      align-self: flex-end;
      padding: 10px;
    }
  }
`;
