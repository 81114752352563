import styled from 'styled-components';

export const MainMenu = styled.nav`
  display: flex;
  position: relative;

  align-items: flex-end;
  justify-content: flex-end;
  align-self: center;
  flex: 1;
  max-width: 700px;

  .menu-mobile {
    display: none;
    cursor: pointer;

    @media (max-width: 980px) {
      display: block;
    }
  }
`;

export const NavList = styled.ul`
  height: 100%;
  display: flex;
  width: 100%;
  align-items: flex-end;
  @media (max-width: 980px) {
    width: 220px;
    flex-direction: column;
    position: absolute;
    right: -40px;
    top: 20px;
    padding: 10px;
    display: ${(props) => (props.visible ? 'inline-table' : 'none')};

    &::before {
      content: '';
      position: absolute;
      right: 40px;
      top: 0px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid var(--color-background-menu);
    }
  }
`;

export const NavItem = styled.li`
  display: flex;
  height: 100%;
  flex: 1;

  align-items: flex-end;

  a {
    width: 100%;
    font-size: 1.4rem;
    text-align: center;
    color: var(--color-menu);
    font-weight: bold;
    animation: background 2s;
    background-color: var(--color-background-menu);
    text-decoration: none;
    padding: 10px;

    &:hover {
      background: var(--color-background-menu-hover);
    }
  }
  &:first-child a {
    border-radius: 30px 0 0 30px;
  }

  &:last-child a {
    border-radius: 0 30px 30px 0;
  }

  @media (max-width: 980px) {
    height: auto;
    a {
      width: 100%;
      padding: 15px;
    }

    &:first-child a {
      border-radius: 30px 30px 0 0px;
    }

    &:last-child a {
      border-radius: 0 0 30px 30px;
    }
  }
`;
