import React from 'react';

import port1 from 'images/port1.png';
import port2 from 'images/port2.png';
import port3 from 'images/port3.png';
import port4 from 'images/port4.png';
import port5 from 'images/port5.png';
import port6 from 'images/port6.png';
import port7 from 'images/port7.png';
import port8 from 'images/port8.png';

import { List } from './styles';

function Galeria() {
  return (
    <List>
      <img src={port1} alt="" />
      <img src={port2} alt="" />
      <img src={port3} alt="" />
      <img src={port4} alt="" />
      <img src={port5} alt="" />
      <img src={port6} alt="" />
      <img src={port7} alt="" />
      <img src={port8} alt="" />
    </List>
  );
}

export default Galeria;
