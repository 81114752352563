import React, { useState } from 'react';

import axios from 'axios';

import { toast } from 'react-toastify';

import { Container } from './styles';

export default function Contact({ ...rest }) {
  const [loading, setLoading] = useState(false);
  function handleSubmit(e) {
    e.preventDefault();
    const form = e.target;
    setLoading(true);
    axios({
      method: 'post',
      url: 'https://apisite.mtistudio.com/contact_moobing',
      data: new FormData(form),
    })
      .then((r) => {
        if (r.data === 1) {
          toast.success('Mensagem enviada com sucesso!', { position: toast.POSITION.BOTTOM_RIGHT });
          document.getElementById('contact-form').reset();
        } else {
          console.log(r.data);
          toast.error('Houve um problema ao enviar sua mensagem', { position: toast.POSITION.BOTTOM_RIGHT });
        }
        setLoading(false);
        // handleServerResponse(true, "Thanks!", form);
      })
      .catch((r) => {
        toast.error('Houve um problema ao enviar sua mensagem', { position: toast.POSITION.BOTTOM_RIGHT });
        setLoading(false);
        // handleServerResponse(false, r.response.data.error, form);
      });
  }
  return (
    <Container {...rest}>
      <h3>Crie. Jogue. Conquiste! Conte com a gente neste desafio. Em breve retornaremos a sua mensagem.</h3>
      <form onSubmit={handleSubmit} id="contact-form">
        <label>
          Nome (obrigatório)
          <input type="text" name="name" id="name" required />
        </label>
        <label>
          E-mail (obrigatório)
          <input type="email" name="email" id="email" required />
        </label>
        <label>
          Assunto (obrigatório)
          <input type="text" name="subject" id="subject" required />
        </label>
        <label>
          Mensagem (obrigatório)
          <textarea name="message" id="message" rows="5" required />
        </label>
        <button type="submit" disabled={loading}>
          {loading ? 'Enviando...' : 'Enviar'}
        </button>
      </form>
    </Container>
  );
}
