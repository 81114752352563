import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  margin-right: 30px;

  p {
    color: var(--color-menu);
    margin-bottom: 10px;
    display: flex;
    align-content: center;
    font-size: 1.5rem;
    svg {
      color: var(--color-menu);
    }
    span {
      margin-left: 10px;
    }
  }

  @media only screen and (max-width: 900px) {
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 60px !important;
    margin-right: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 30px;
  }
`;

export const Body = styled.div``;
