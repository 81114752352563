import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  z-index: 30;
  position: relative;

  img {
    width: 16.66%;
    padding: 5px;
  }

  @media only screen and (max-width: 900px) {
    img {
      width: 33.3%;
    }
  }
  @media only screen and (max-width: 600px) {
    img {
      width: 100%;
    }
  }
`;
