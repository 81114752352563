import React from 'react';

import port9 from 'images/port9.png';
import port10 from 'images/port10.png';
import port11 from 'images/port11.png';
import port12 from 'images/port12.png';
import port13 from 'images/port13.png';
import port14 from 'images/port14.png';

import { List } from './styles';

function Galeria() {
  return (
    <List>
      <img src={port9} alt="" />
      <img src={port10} alt="" />
      <img src={port11} alt="" />
      <img src={port12} alt="" />
      <img src={port13} alt="" />
      <img src={port14} alt="" />
    </List>
  );
}

export default Galeria;
